<div class="flex flex-col items-center justify-center">
  <form nz-form [formGroup]="form" class="report-form">
    <nz-form-item>
      <nz-form-control [nzValidateStatus]="formControls.title">
        <input nzSize="large" nz-input [formControl]="formControls.title" placeholder="Issue Title *"
          class="font-semibold h-custom-input text-15" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzValidateStatus]="formControls.description">
        <textarea nzSize="large" nz-input [formControl]="formControls.description"
          placeholder="Describe the issue here.." class="font-semibold h-custom-input text-15" rows="3"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzValidateStatus]="formControls.email">
        <input nzSize="large" nz-input [formControl]="formControls.email" placeholder="Your Email *"
          class="font-semibold h-custom-input text-15" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzValidateStatus]="formControls.mobile">
        <input nzSize="large" nz-input [formControl]="formControls.mobile" placeholder="Your Mobile Number"
          class="font-semibold h-custom-input text-15" />
      </nz-form-control>
    </nz-form-item>
    <div class="flex flex-col justify-center items-center mb-4">
      <label for="image-file-uploader-input" [ngClass]="{'img-disabled': selectedFiles.length >= 3}"
        class="flex items-center border-secondary border bg-secondary cursor-pointer px-8 py-2">
        <span class="text-2xl material-symbols-rounded leading-none">{{ materialIcon.CAMERA }}</span>
        <span class="text-13 font-semibold leading-none pl-1">Add Image/Video</span>
      </label>
      <input type="file" name="file" id="image-file-uploader-input" accept="image/*,video/*"
        [disabled]="selectedFiles.length >= 3" (change)="onInputChange($event)">
      <!-- <div class="mt-6 flex justify-evenly flex-wrap gap-4" *ngIf="formControls.files.controls.length">
        <div class="form-image-container flex items-center border-secondary border" formArrayName="files"
          *ngFor="let fileForm of formControls.files.controls; let fileIndex = index;">
          <div [formGroupName]="fileIndex" class="h-full w-full cursor-pointer" nz-tooltip
            [nzTooltipTitle]="fileForm.controls.fileName.value" nzTooltipPlacement="bottom">
            <! -- <app-file-view [type]="fileForm.controls.fileType.value"
              [source]="filePath+fileForm.controls.filePath.value"></app-file-view> -- >
          </div>
        </div>
      </div> -->
    </div>
    <div class="flex justify-center items-center flex-col" *ngIf="selectedFiles && selectedFiles.length === 3">
      <span class="pb-3 text-rejected">Max 3 Files Only</span>
    </div>
    <div class="flex justify-center mb-2" *ngIf="selectedFiles && selectedFiles.length">
      <div *ngFor="let image of selectedFiles"
        class="flex items-center justify-center h-3/4 bg-popover relative w-1/2 p-2">
        <img *ngIf="image.type && !image.type.includes('video')" [src]="image.imageSrc" class="max-h-full max-w-full" />
        <span *ngIf="image.type && image.type.includes('video')"
          class="material-symbols-rounded selected text-6xl cursor-pointer">{{ materialIcon.VIDEO }}</span>
      </div>
    </div>
    <div class="flex justify-center items-center flex-col">
      <span class="pb-4 text-rejected" *ngIf="errorMessage">{{errorMessage}}</span>
    </div>
  </form>
  <!-- <div class="w-full flex justify-center mb-4">
    <re-captcha #invisible [(ngModel)]="startRecaptcha" size="invisible" name="recaptcha">
    </re-captcha>
  </div> -->
</div>
<div class="flex justify-center">
  <nz-spin nzSimple [nzSpinning]="isSubmitting" nzTip="Please wait..."></nz-spin>
  <button *ngIf="!isSubmitting" nz-button class="bg-inherit border-0" [disabled]="!form.valid"
    (click)="submitFormData()">
    <span class="text-6xl cursor-pointer material-symbols-rounded leading-none save-icon"
      [ngClass]="{'selected': form.valid}">{{ materialIcon.ARROW_CIRCLE_RIGHT }}</span>
  </button>
</div>