export enum MaterialIcon {
  SOLAR = 'wb_sunny',
  WIND = 'wind_power',
  LIVE = 'settings_input_component',
  CMMS = 'build_circle',
  ANALYTICS = 'area_chart',
  DATAQUERYMODULE = 'calculate',
  MAP = 'public',
  SETTINGS = 'settings',
  LOGOUT = 'power_settings_new',
  ENERGYSTATISTICS = 'offline_bolt',
  VISUALIZATION = 'leaderboard',
  POWERCURVE = 'data_thresholding',
  OBSERVATIONS = 'tips_and_updates',
  WORKORDERS = 'fact_check',
  MAINTENANCEMASTER = 'calendar_month',
  ASSETS = 'category',
  ADD = 'add_box',
  GRAPH = 'poll',
  EDIT = 'drive_file_rename_outline',
  SAVE = 'save',
  UPLOAD = 'cloud_upload',
  DOWNLOAD = 'cloud_download',
  CSV = 'download_for_offline',
  BULKEDIT = 'auto_awesome_motion',
  CLOSE = 'disabled_by_default',
  DELETE = 'delete',
  INFO = 'info',
  PUSHPIN = 'push_pin',
  TIMESERIES = 'schedule',
  BOXPLOT = 'candlestick_chart',
  TAG = 'account_tree',
  HEATMAP = 'calendar_view_month',
  LINE = 'broken_image',
  SCATTER = 'scatter_plot',
  ADDCOMMENT = 'add_comment',
  PLAYARROW = 'play_arrow',
  DOCUMENT = 'description',
  CLOSE_NORMAL = 'close',
  CANCEL = 'highlight_off',
  ATTACHMENT = 'attachment',
  QUESTION = 'help',
  ARROW_CIRCLE_RIGHT = 'arrow_circle_right',
  THERMOSTAT = 'device_thermostat',
  NOTIFICATION = 'notifications',
  COMMENT = 'comment',
  AIR = 'air',
  USERS = 'group',
  INVITE_USER = 'person_add',
  SYNC = 'cloud_sync',
  MORE_HORIZ = 'more_horiz',
  ADD_NORMAL = 'add',
  CHECK_CIRCLE = 'check_circle',
  REPORT_PROBLEM = 'report_problem',
  CONTRACTORS = 'supervised_user_circle',
  LIGHTBULB = 'lightbulb',
  WAVES = 'waves',
  ALARM = 'notifications_active',
  SUB_ASSETS = 'inventory_2',
  BUILD = 'build',
  VENDOR = 'recent_actors',
  MANUFACTURER = 'factory',
  CALENDAR = 'today',
  CAMERA = 'photo_camera',
  LOCATION = 'location_city',
  FILE_COPY = 'file_copy',
  SEARCH = 'search',
  ADD_LOCATION = 'add_location_alt',
  CONTACTS = 'contacts',
  PERSON = 'person',
  ADDRESS = 'contact_mail',
  EMAIL = 'email',
  CITY = 'location_on',
  PINCODE = 'pin',
  MOBILE = 'smartphone',
  OTHER_PHONE = 'phone',
  PRIORITY = 'priority_high',
  MAKE = 'coffee_maker',
  ASSET_STATUS = 'flaky',
  INVENTORY = 'build_circle',
  WAREHOUSE = 'holiday_village',
  QUANTITY = 'shopping_basket',
  UNITS = 'scale',
  VIDEO = 'play_circle',
  PDF = 'picture_as_pdf',
  GIF = 'gif',
  ADD_PHOTO = 'add_photo_alternate',
  ATTACH_FILE = 'attach_file',
  PHOTO_LIBRARY = 'photo_library',
  CHAT = 'chat_bubble',
  CREDIT_CARD = 'credit_card',
  TITLE = 'title',
  TEXTFIELDS = 'text_fields',
  EVENT = 'event',
  PIN = 'pin',
  MONEY = 'paid',
  LISTALT = 'list_alt',
  VIEW = 'visibility',
  USER = 'account_circle',
  BUSINESS = 'business',
  VIEWDAY = 'view_day',
  FORMATSIZE = 'format_size',
  ROLE = 'admin_panel_settings',
  COMPANY = 'business',
  SUBSCRIBE = 'monetization_on',
  PAUSECIRCLE = 'pause_circle',
  INVOICES = 'receipt_long',
  RESUME = 'pause_circle',
  REFRESH = 'refresh',
  PERCENT = 'percent',
  DONE = 'done',
  BOOKMARK_ADDED = 'bookmark_added',
  EAST = 'east',
  WEST = 'west',
  BARCODE = 'qr_code',
  PRINT = 'print',
  CHECKLIST = 'checklist',
  DEFAULTWAREHOUSE = 'warehouse',
  CHECK = 'check',
  ADDCARD = 'add_card',
  FILTERALT = 'filter_alt',
}

export enum RouterLinks {
  WorkOrders = 'work-orders',
  MaintenanceMaster = 'maintenance-master',
  Assets = 'assets',
  Inventory = 'inventory',
  Billing = 'billing',
  Settings = 'settings'
}

export enum AssetType {
  Asset = 'asset',
  SubAsset = 'subasset',
  Part = 'part',
  SubAssetPart = 'subassetpart'
}

export enum AssetLevelNames {
  'asset' = 'Asset',
  'subasset' = 'Sub Asset',
  'part' = 'Part'
};

export enum ApiUrls {
  razorPay = 'rp/',
  billing = 'billing/',
  Login = 'user/login',
  ForgotPassword = 'user/reset-invite',
  Signup = 'user/signup',
  ValidateCode = 'index/validate-code',
  Invite = 'user/signup-invite',
  SignupVerify = 'user/signup-verify',
  ResetVerify = 'user/reset-verify',
  InviteVerify = 'user/invite-verify',
  ResetPassword = 'user/reset-password',
  createCompany = 'user/create-company',
  LinkUser = 'user/link-user',
  CheckStatus = 'user/check-status',
  UpdateUserDetails = 'user/update-company',
  SelectCompany = 'user/select-company',
  MapBox = 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
  GetLocations = 'location/list',
  AddLocation = 'location/add',
  UpdateLocation = 'location/update',
  DeleteLocation = 'location/delete',
  GetAssetDetailsByAssetId = 'asset/details',
  GetAssetPublicDetails = 'asset/public-details',
  GetAssetsByLocation = 'asset/list',
  GetFullAssetsByLocation = 'asset/full-list',

  AddAsset = 'asset/add',
  UpdateAsset = 'asset/update',
  DeleteAsset = 'asset/delete',
  CopyAsset = 'asset/copy',
  SearchAsset = 'asset/search',
  TreeAsset = 'asset/dropdown-tree',
  DownloadAssetsCSV = 'asset/download-csv',
  UploadAssetsCSV = 'asset/upload-csv',

  MapBoxAccessKey = 'pk.eyJ1Ijoia2lzaG9yLWRlIiwiYSI6ImNsMnEwMGRiazJwcDUzZXFoYXo5cjE3dncifQ.IKRrcZtD98B6dsjS3B3ixA',
  GetFileRepositoryImages = 'file/list',
  GetFileUploadToken = 'file/get-upload-token',
  GetBCDNUploadToken = 'file/get-bcdn-token',
  UpdateFileData = 'file/add',
  GetPublicFileUploadToken = 'file/get-public-upload-token',
  UpdatePublicFileData = 'file/add-public',

  GetLists = 'common/lists',
  GetManufacturers = 'manufacturer/list',
  AddManufacturer = 'manufacturer/add',
  UpdateManufacturers = 'manufacturer/update',
  DeleteManufacturers = 'manufacturer/delete',
  GetVendors = 'vendor/list',
  AddVendor = 'vendor/add',
  UpdateVendor = 'vendor/update',
  DeleteVendor = 'vendor/delete',
  GetParts = 'inventory/parts-list',
  GetPartCount = 'inventory/part-count',
  GetWorkOrders = 'work-order/list',
  GetWorkOrder = 'work-order/get',
  AddWorkOrder = 'work-order/add',
  UpdateWorkOrder = 'work-order/update',
  DeleteWorkOrder = 'work-order/delete',
  DownloadWorkOrder = 'work-order/download',
  WorkOrderUpdated = 'work-order/update-time',
  WorkOrderProofUpdate = 'work-order/update-checklist-proof',
  WorkOrderProofDelete = 'work-order/delete-checklist-proof',
  GetWorkOrderOrderCount = 'work-order/order-counts',
  GetMaintenanceActivities = 'activity/list',
  AddMaintenanceActivities = 'activity/add',
  UpdateMaintenanceActivities = 'activity/update',
  DeleteMaintenanceActivity = 'activity/delete',
  GetUsers = 'user/list',
  GetUserWithPermissions = 'user/permissions',
  AddUserPermission = 'user/invite-user',
  UpdateUserPermission = 'user/update-user',
  DeleteUserPermission = 'user/delete-user',
  UpdateLogo = 'user/update-logo',
  GetLocationNames = 'location/dropdown-list',
  GetTypes = 'asset-type/list',
  AddType = 'asset-type/add',
  UpdateType = 'asset-type/update',
  DeleteType = 'asset-type/delete',
  GetMakes = 'make/list',
  AddMake = 'make/add',
  UpdateMake = 'make/update',
  DeleteMake = 'make/delete',
  GetModels = 'model/list',
  AddModel = 'model/add',
  UpdateModel = 'model/update',
  DeleteModel = 'model/delete',
  GetAssetStatuses = 'asset-status/list',
  AddAssetStatus = 'asset-status/add',
  UpdateAssetStatus = 'asset-status/update',
  DeleteAssetStatus = 'asset-status/delete',
  GetInventories = 'inventory/list',
  GetInventoryData = 'inventory/get',
  AddInventory = 'inventory/add',
  UpdateInventory = 'inventory/update',
  DeleteInventory = 'inventory/delete',
  GetStockEntries = 'stock/list',
  SaveStockEntry = 'stock/save-entry',
  GetWarehouses = 'warehouse/list',
  AddWarehouse = 'warehouse/add',
  UpdateWarehouse = 'warehouse/update',
  DeleteWarehouse = 'warehouse/delete',
  GetUnits = 'unit/list',
  AddUnit = 'unit/add',
  UpdateUnit = 'unit/update',
  DeleteUnit = 'unit/delete',
  getAssetCustomFields = 'asset-custom-field/list',
  addAssetCustomField = 'asset-custom-field/add',
  updateAssetCustomField = 'asset-custom-field/update',
  getCurrency = 'list/currency',
  getTimezones = 'list/timezones',
  addCard = 'billing/save-card',
  deleteCard = 'billing/delete-card',
  setDefaultCard = 'billing/set-default-card',
  getCards = 'billing/get-card-details',

  getInvioces = billing + razorPay + 'invoices',
  saveCompanyDetails = billing + razorPay + 'save-legal-company-details',
  createSubscription = billing + razorPay + 'subscribe-plan',
  pauseSubscription = billing + razorPay + 'pause-subscription',
  resumeSubscription = billing + razorPay + 'resume-subscription',
  cancelSubscription = billing + razorPay + 'cancel-subscription',
  verifyPayment = billing + razorPay + 'verify-payment',
  validateCoupon = billing + razorPay + 'validate-coupon',
  getStatus = billing + razorPay + 'get-status',
  trackSubscription = billing + razorPay + 'track-subscription',


  checkPendingInvoices = 'billing/check-pending-invoices',
  //validateCoupon = 'billing/validate-coupon',
  //getStatus = 'billing/get-status',

  GetCategories = 'category/list',
  AddCategory = 'category/add',
  UpdateCategory = 'category/update',

  GetChecklists = 'checklist/list',
  AddChecklist = 'checklist/add',
  UpdateChecklist = 'checklist/update',
  AddChecklistItem = 'checklist/add-item',
  UpdateChecklistItem = 'checklist/update-item',

  Report = 'work-order/add-notification'
}

export enum FeatureKeys {
  Asset = 'assets',
  Maintenance = 'maintenance',
  WorkOrder = 'workOrder'
}

export enum BillingStatus {
  Active = 'active',
  TrailMode = 'trial_mode',
  TrailEnd = 'trial_end',
  PendingInvoice = 'pending_invoice',
  Pending = 'pending',
  AutoPause = 'auto_pause',
  ManualPause = 'manual_pause',
  Cancelled = 'cancelled',

}
export enum BillingMessages {
  Active = '',
  TrailMode = 'Trial Mode Till ',
  TrailEnd = 'Trial Expired - Start Subscription',
  PendingInvoice = 'Invoice Pending',
  AutoPause = 'Subscription Paused - Invoice Pending',
  ManualPause = 'Subscription Paused by Admin'
}

