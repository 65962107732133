import { NzNotificationService } from "ng-zorro-antd/notification";
import { ServerErrorMessage } from "./common.constants";
import { ManufacturerModel, TableFilterDataModel, TextValueModel, UserModel, VendorModel } from "./common.model";
import * as moment from "moment";
import { LocationMenuModel } from "../shared/components/location/model/location.model";

export const isEmpty = (obj: any): boolean => {
  return obj !== undefined && obj !== null;
}

export const isNumber = (input: any): boolean => {
  if (!input) return false;
  const formattedInput = input.toString().replace(/ /g, '').replace(/,/g, '');
  return formattedInput ? !isNaN(formattedInput) : false;
}

export const textValueTrackBy = (index: number, item: TextValueModel) => {
  return item.value;
}

export const trackByManufacturerId = (index: number, manufacturer: ManufacturerModel) => {
  return manufacturer.manufacturerId;
}

export const trackByVendorId = (index: number, vendor: VendorModel) => {
  return vendor.vendorId;
}

export const trackByUserId = (index: number, user: UserModel) => {
  return user.uid;
}

export const getFileNameExt = (fileName: string) => {
  if (fileName) {
    const nameArray = fileName.split('.');
    return { name: nameArray[0], ext: nameArray[1] };
  }
  return { name: '', ext: '' };
}

export const getFileExtension = (fileName: string) => {
  return fileName.split('.').pop();
}

export const cleanFileName = (name: string, fileExt: string) => {
  const cleanedName = name.replace(/\s+/g,'_');
  const fileName = `${cleanedName.substring(0, cleanedName.length - fileExt.length - 1)}_${moment.now()}.${fileExt}`;
  return fileName;
}

export const getDifference = (a: any, b: any) => Object.fromEntries(Object.entries(b).filter(([key, val]) => key in a && a[key] !== val));

export const filtersToPayload = (filterData: TableFilterDataModel[]) => {
  return filterData.reduce((acc, curr) => {
    if (curr.value && curr.value.length) {
      return { ...acc, [curr.key]: curr.value.toString() }
    }
    return { ...acc };
  },{})
}

export const isValidDateFormat = (input: string) => {
  const dateRegex = /(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0,1,2])\/(19|20)\d{2}/;

  return dateRegex.test(input);
}

export const formatDueDate = (dueDate: any) => {
  return dueDate.getFullYear() + '-' + (dueDate.getMonth() + 1).toString().padStart(2, '0') + '-' + dueDate.getDate().toString().padStart(2, '0');
}

export const getPercentage = (fileSize: number, fileType: string) => {
  const percentage = fileType.includes('png') ? 50 : 80;
  return Math.ceil(((1024 / fileSize) * 1024) * percentage);
}

export const showErrorMsg = (messageService: NzNotificationService, message: string) => {
  messageService.create('error',  'Error', message || ServerErrorMessage, { nzClass: 'error' });
}

export const showSuccessMsg = (messageService: NzNotificationService, message: string) => {
  messageService.create('success',  'Success', message, { nzClass: 'success' });
}

export const downloadFile = (exportedFilename: string, blob: string, extension = 'pdf') => {
  const link = document.createElement('a');
  if (link.download !== undefined) { // feature detection
    // Browsers that support HTML5 download attribute
    //const url = URL.createObjectURL(blob);
    link.setAttribute('href', blob);
    link.setAttribute('download', exportedFilename + '.' + extension);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export const downloadCSV = (headers: any, items: any[], fileTitle: string): void => {
  try {
    if (headers) {
      items.unshift(headers);
    }

    // Convert Object to JSON
    const jsonObject = JSON.stringify(items);
    const csv = convertToCSV(jsonObject);
    const exportedFilenmae = fileTitle + '.csv' || 'export.csv';
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (e) {
    console.log(e);
  }
}

export const convertToCSV = (objArray: any): string => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  const h = array[0];
  for (const i in array) {
    if (array.hasOwnProperty(i)) {
      let line = '';
      let first = true;
      for (const index in h) {
        if (h.hasOwnProperty(index)) {
          if (first) {
            first = false;
          } else {
            line += ',';
          }
          const value = array[i][index];
          line += value ? `"${value}"` : '';
        }
      }
      str += line + '\r\n';
    }
  }
  return str;
}

export const getLocationNames = (locationId: string, locationsList: LocationMenuModel[]) => {
  let locationName = '';
  let subLocationName = '';
  if (locationsList && locationsList.length) {
    const locationMenuData = locationsList.find(l => l.locationId === locationId);
    if (locationMenuData) {
      locationName = locationMenuData.locationName;
    } else {
      locationsList.forEach(location => {
        if (location.subLocations && location.subLocations.length) {
          const subLocationData = location.subLocations.find((s: any) => s.locationId === locationId);
          if (subLocationData) {
            console.log(subLocationData);
            locationName = location.locationName;
            subLocationName = subLocationData.locationName;
          }
        }
      });
    }
  }
  return { locationName, subLocationName };
}
