export const environment = {
  production: true,
  env: 'staging',
  baseUrl: 'https://staging-api.supercmms.com/',
  fileRepositoryUrl: 'https://staging.supercmms.workers.dev',
  bcdnLibraryId: '380579',
  bcdnHostName: 'vz-5167f67d-ad2.b-cdn.net',
  mapBoxAccessKey: 'pk.eyJ1Ijoia2lzaG9yLWRlIiwiYSI6ImNsMnEwMGRiazJwcDUzZXFoYXo5cjE3dncifQ.IKRrcZtD98B6dsjS3B3ixA',
  featureToggles: {},
  firebaseConfig: {
    apiKey: "AIzaSyBZLCBPZy_GPlD9XY_pen7zXBmtBDJb-sc",
    authDomain: "super-cmms-staging.firebaseapp.com",
    projectId: "super-cmms-staging",
    storageBucket: "super-cmms-staging.appspot.com",
    messagingSenderId: "396694979142",
    appId: "1:396694979142:web:d0fdbb40351470f1164047",
    measurementId: "G-TCW3XNP7MS"
  },
  RECAPTCHA_V3_KEY: '6LdHlzkhAAAAAJtZSI6nka3Tc8zh1hIpRhbwS23A',
  RECAPTCHA_V2_KEY: '6LdoyDkhAAAAALzaY0BV_IEEny5-W7czq7FMGp-F',
  STRIPE_KEY: 'pk_test_51LehBHSHXPx5XZjsKzrsVNfYE6YLDePCwkz2Rvyx3hEwJaku2TU75EUfsoWrCxe0GcjuUTOhTZgF5j2ZuH1ZHshp00wrNsAUjU'
};
