import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Upload } from 'tus-js-client';
// import * as moment from 'moment';
import { Observable, concatMap, of } from 'rxjs';
import { ApiUrls } from 'src/app/util/common.enum';
import { environment } from 'src/environments/environment';
import { ImageFileModel } from '../model/image-file.model';

@Injectable()
export class ImageFileRepositoryService {

  constructor(private httpClient: HttpClient) { }

  getImages(locationId: string): Observable<ImageFileModel[]> {
    const url = locationId ? `?locationId=${locationId}` : ``;
    return this.httpClient.get<ImageFileModel[]>(`${environment.baseUrl}${ApiUrls.GetFileRepositoryImages}${url}`);
  }

  uploadFile(formData: any, token: string, path: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'X-Custom-Auth-Key': token,
        'X-File-Path': path,
      }),
      responseType: 'text' as const
    }
    return this.httpClient.put(`${environment.fileRepositoryUrl}`, formData, options);
  }

  getToken(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.baseUrl}${ApiUrls.GetFileUploadToken}`);
  }

  getBCDNToken(title: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.baseUrl}${ApiUrls.GetBCDNUploadToken}?videoTitle=${title}`);
  }

  uploadVideoToBCDN(file: any, bcdnData: any): Promise<any> {
    console.log('file', bcdnData);
    const { signature, libraryId, videoId, collectionId, expirationTime } = bcdnData;
    const promise = new Promise(async (resolve, reject) => {
      const upload = new Upload(file, {
        endpoint: "https://video.bunnycdn.com/tusupload",
        retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
        headers: {
            AuthorizationSignature: signature, // SHA256 signature (library_id + api_key + expiration_time + video_id)
            AuthorizationExpire: expirationTime, // Expiration time as in the signature,
            VideoId: videoId, // The guid of a previously created video object through the Create Video API call
            LibraryId: libraryId,
        },
        metadata: {
            filetype: file.type,
            title: file.name,
            collection: collectionId
        },
        onError: (error) => {
          console.log('Failed because: ' + error)
          reject(error);
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
          console.log(bytesUploaded, bytesTotal, percentage + '%')
        },
        onSuccess: () => {
          console.log('Upload success', upload.file, upload.url)
          resolve({ ...upload.file, ...bcdnData });
        },
      })
      upload.start();
    });

    return promise;
  }

  updateFileData(data: any): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}${ApiUrls.UpdateFileData}`, data);
  }

  getPublicToken(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.baseUrl}${ApiUrls.GetPublicFileUploadToken}`);
  }

  updatePublicFileDb(data: any): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}${ApiUrls.UpdatePublicFileData}`, data);
  }

  uploadCompanyIcon(fileName: string) {
    return this.httpClient.post(`${environment.baseUrl}${ApiUrls.UpdateLogo}`, { logo: fileName });
  }

  uploadToServer(file: File, fileExt: string, companyId: string, locationId: string, fileName: string, isThumb: boolean, dbFlag: boolean) {
    const path = this.getPath(fileName, companyId, locationId, isThumb);
    const formData: FormData = new FormData();
    formData.append('file', file, fileName);
    const fileData = {
      locationId: locationId || '',
      fileName,
      fileType: file.type,
      fileSize: file.size,
      fileExt: fileExt
    }
    if (file.type.includes('video')) {
      console.log('Uploading video', file);
      return this.getBCDNToken(file.name).
        pipe(
          concatMap(async (value: any) => await this.uploadVideoToBCDN(file, value.data))
        )
    }
    return this.getToken().
      pipe(
        concatMap((value: any) => this.uploadFile(file, value.token, path)),
        concatMap(() => {
          if (!isThumb && dbFlag) {
            return locationId ? this.updateFileData(fileData) : this.uploadCompanyIcon(fileName);
          } else {
            return of(fileData);
          }
        })
      )
  }

  uploadToServerPublic(file: File, fileExt: string, companyId: string, locationId: string, fileName: string, isThumb: boolean, email: string) {
    const path = this.getPath(fileName, companyId, locationId, isThumb);
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const fileData = {
      companyId,
      locationId: locationId || '',
      fileName,
      fileType: file.type,
      fileSize: file.size,
      fileExt: fileExt,
      email: email || ''
    }
    return this.getPublicToken().
      pipe(
        concatMap((value: any) => this.uploadFile(file, value.token, path)),
        concatMap(() => {
          if (!isThumb) {
            return this.updatePublicFileDb(fileData);
          } else {
            return of(fileData);
          }
        })
      )
  }

  getPath(fileName: string, companyId: string, locationId: string, isThumb: boolean) {
    locationId = locationId ? `${locationId}/` : '';
    if (isThumb) {
      return `${companyId}/${locationId}thumb/${fileName}`;
    }
    return `${companyId}/${locationId}${fileName}`;
  }

}
